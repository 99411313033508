<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
function fn(event) {
  console.log("========fn=========");
  const { token } = event.data;
  token && localStorage.setItem("token", token);
}
export default {
  name: "App",
  components: {},
  created() {},
  mounted() {
    window.addEventListener("message", fn);
  },
  beforeDestroy() {
    console.log("app destory");
    window.removeEventListener("message", fn);
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
