import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

import './plugin/element-ui'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.use(dataV)