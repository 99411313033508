export default {
    namespaced: true,
    actions: {

    },
    mutations: {
        addData(state,item){
            state.datalist.push({
                id:item[0],
                title:item[1],
                content:item[2]
            })
        },
        removeData(state){
            state
        }

    },
    state: {
        datalist: [{
                id: 1,
                title: "一致性 Consistency",
                content: [
                    "与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；",
                    "在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。",
                ],
            },
            {
                id: 2,
                title: "反馈 Feedback",
                content: [
                    "简化流程：设计简洁直观的操作流程；",
                    "页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。",
                ],
            },
            {
                id: 3,
                title: "效率 Efficiency",
                content: [
                    "结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。",
                    "帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。",
                ],
            },
        ],
    },
    getters: {

    }
}