import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  //首页
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
    },
    component: () => import("../view/home/Home.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
