import Vue from 'vue'
import Vuex from 'vuex'
import dataList from './dataList'

Vue.use(Vuex)


//创建并暴露store
export default new Vuex.Store({
    modules: {
        dataList
    }
})